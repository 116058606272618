import React from 'react'
import Home from './Home'
import Footer from "../Footer";
import Features from './Features';


const Index = () => {
  return (
    <div>
        <Home/>
        <Features/>
    </div>
  )
}

export default Index